(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("adaptivecards"), require("adaptivecards-templating"));
	else if(typeof define === 'function' && define.amd)
		define([, ], factory);
	else if(typeof exports === 'object')
		exports["ACDesigner"] = factory(require("adaptivecards"), require("adaptivecards-templating"));
	else
		root["ACDesigner"] = factory(root["AdaptiveCards"], root["ACData"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__2383__, __WEBPACK_EXTERNAL_MODULE__5721__) {
return 